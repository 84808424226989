import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';

const Profile = ({ user }) => {
    // const [position, setPosition] = useState({ x: 0, y: 0 });
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileData, setProfileData] = useState({
        fullName: '',
        companyName: '',
        email: '',
        country: '',
        state: '',
        city: '',
        address: '',
        pincode: '',
        mobileNumber: '',
        landlineNumber: '',
        businessList: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(true);

    // Load user profile data on component mount
    const apiUrl = window.location.origin.includes('www') 
        ? 'https://www.tionet.in/node/api/auth/user/profile' 
        : 'https://tionet.in/node/api/auth/user/profile';

    // Fetch user profile data from the appropriate API
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get(apiUrl);
                setProfileData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile data:', error);
                setLoading(false); 
            }
        };

        fetchProfileData();
    }, [apiUrl]);

    

    // Handle form submission to update profile
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put('https://tionet.in/node/auth/user/profile', profileData);
            alert('Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({ ...profileData, [name]: value });
    };

    // // Make profile draggable
    // const handleMouseDown = (e) => {
    //     e.preventDefault();
    //     const startX = e.clientX - position.x;
    //     const startY = e.clientY - position.y;

    //     const handleMouseMove = (e) => {
    //         const newX = e.clientX - startX;
    //         const newY = e.clientY - startY;
    //         if (newX >= 0 && newY >= 0 && newX + 300 <= window.innerWidth && newY + 400 <= window.innerHeight) {
    //             setPosition({ x: newX, y: newY });
    //         }
    //     };

    //     const handleMouseUp = () => {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //         window.removeEventListener('mouseup', handleMouseUp);
    //     };

    //     window.addEventListener('mousemove', handleMouseMove);
    //     window.addEventListener('mouseup', handleMouseUp);
    // };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const image = URL.createObjectURL(e.target.files[0]);
            setSelectedImage(image);
        }
    };
    
    

    // if (loading) {
    //     return <div>Logged-in to Unlock this features</div>; 
    // }

    return (
        <div className="profile-container"
              style={{
                  display: 'block',
                  position: 'absolute',
                //  transform: `translate(${position.x}px, ${position.y}px)`,
                 zIndex: 1000,
             }}
            //  onMouseDown={handleMouseDown}
        >
          
            <div className="profile-top">
                <h2>Profile</h2>
                
               
                <div className="button-container">
                   
                    {isEditMode && (
                        <>
                            <input 
                                type="file" 
                                id="imageUpload" 
                                accept="image/*" 
                                style={{ display: 'none' }} 
                                onChange={handleImageChange} 
                            />
                            <button 
                                id="uploadButton" 
                                className="imageupload" 
                                onClick={() => document.getElementById('imageUpload').click()}
                            >
                                Update Image
                            </button>
                        </>
                    )}
    
                    {/* Toggle edit mode button */}
                    <button 
                        id="updateInfoButton" 
                        className="editprofile" 
                        onClick={() => setIsEditMode(!isEditMode)}
                    >
                        {isEditMode ? 'Cancel' : 'Edit Profile'}
                    </button>
                </div>
            </div>
    
            {/* Profile Image Preview */}
            {selectedImage && (
                <div className="image-preview">
                      <img src={selectedImage || user.photo} alt="Profile" className="profile-image" />
                </div>
            )}
    
          
    <div className="sub-container">
                <div className="user-details-container">
                    <form className="userprofile" onSubmit={handleSubmit}>
                        <span className='userdetails'>User Details</span>
                        <div className="formcontainer">
                        <div className="input-box">
                                {/* Full Name */}
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="fullName"
                                            value={profileData.fullName || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="fullName">Full Name</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Full Name: </span>
                                        <div className="dummy-text-box">
                                            {profileData.fullName || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
  {/* Company Name */}
  <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={profileData.companyName || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="companyName">Company Name</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Company Name: </span>
                                        <div className="dummy-text-box">
                                            {profileData.companyName || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>

  {/* Email */}
  <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            name="email"
                                            value={profileData.email || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Email: </span>
                                        <div className="dummy-text-box">
                                            {profileData.email || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
 {/* Country */}
 <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="country"
                                            value={profileData.country || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="country">Country</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Country: </span>
                                        <div className="dummy-text-box">
                                            {profileData.country || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>



                            {/* State */}
                            <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="state"
                                            value={profileData.state || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="state">State</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">State: </span>
                                        <div className="dummy-text-box">
                                            {profileData.state || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
  {/* City */}
  <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="city"
                                            value={profileData.city || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="city">City</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">City: </span>
                                        <div className="dummy-text-box">
                                            {profileData.city || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
  {/* Address */}
  <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="address"
                                            value={profileData.address || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="address">Address</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Address: </span>
                                        <div className="dummy-text-box">
                                            {profileData.address || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>

 {/* Pincode */}
 <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            name="pincode"
                                            value={profileData.pincode || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="pincode">Pincode</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Pincode: </span>
                                        <div className="dummy-text-box">
                                            {profileData.pincode || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
   {/* Mobile Number */}
   <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            name="mobileNumber"
                                            value={profileData.mobileNumber || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="mobileNumber">Mobile Number</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Mobile Number: </span>
                                        <div className="dummy-text-box">
                                            {profileData.mobileNumber || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>
 {/* Landline Number */}
 <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="tel"
                                            name="landlineNumber"
                                            value={profileData.landlineNumber || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="landlineNumber">Additional Number</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Additional Number: </span>
                                        <div className="dummy-text-box">
                                            {profileData.landlineNumber || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>

  {/* Business List */}
  <div className="input-box">
                                {isEditMode ? (
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="businessList"
                                            value={profileData.businessList || ''}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="businessList">Business List</label>
                                    </div>
                                ) : (
                                    <div className="details-box">
                                        <span className="details">Business List: </span>
                                        <div className="dummy-text-box">
                                            {profileData.businessList || 'Loading...'}
                                        </div>
                                    </div>
                                )}
                            </div>



    
                    {/* Save button */}
                    {isEditMode && (
                                <div className="input-box">
                                    <button
                                        type="button"
                                        className="submit-button"
                                        onClick={handleSubmit}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            )}
                        </div>
                        
                    </form>
                    
                </div>

                <div className="box-wrapper">
                    <div className="subscription-card">
                        <div className="cover">
                            <div className="subscription-header">
                                <h3>Active Subscription Plan</h3>
                            </div>
                            <div className="subscription-details">
                                <p><strong>Plan:</strong> Premium</p>
                                <p><strong>Subscription Date:</strong> 2024-10-01</p>
                                <p><strong>Expires On:</strong> 2025-10-01</p>
                                <p><strong>Days Remaining:</strong> <span id="daysRemaining">365</span> days</p>
                            </div>
                        </div>
                        <div className="card-back">
                            <a href="#" className="renew-button">Renew Now</a>
                        </div>
                    </div>

                    <div className="money-container">
                        <p>Advertisement Section</p>
                        <img src="ad-image.jpg" alt="Ad" />
                        <p>Promote your content here!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;