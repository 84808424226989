import React from 'react';

const Settings = () => {
  return (
    <div>
      <h2>Settings Page</h2>
      <p>This is the settings page where users can adjust their preferences.</p>
    </div>
  );
};

export default Settings;
