import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Dropdown.css';
import PPImage from '../assets/PP.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Profile from './Profile';
import LatestNews from './LatestNews';


const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showLatestNews, setShowLatestNews] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const imageUploadRef = useRef(null);
  const [user, setUser] = useState({
    name: 'Guest',
    photo: PPImage,
  });
   
  const [position, setPosition] = useState({
    x: 100, // Initial horizontal position
    y: 100, // Initial vertical position
  });

  useEffect(() => {
    console.log('useEffect triggered'); 
  
    const fetchUserData = async () => {
      try {
          
          const apiUrl = window.location.origin.includes('www') 
              ? 'https://www.tionet.in/node/api/auth/user/dropdown' 
              : 'https://tionet.in/node/api/auth/user/dropdown';
  
          const response = await axios.get(apiUrl, {
              withCredentials: true,  
              headers: {
                  'Accept': 'application/json', 
              }
          });
  
  
        console.log('Response Data:', response.data);  // Check the exact data received
        if (response.headers['content-type'].includes('application/json')) {
          if (response.data) {
            setUser({
              name: response.data.fullName || 'Guest',
              photo: response.data.profilePhoto || PPImage,
            });
          }
        } else {
          console.error('Unexpected content type:', response.headers['content-type']);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      adjustDropdownPosition();
    }

    // Close all content when closing the dropdown
    if (isOpen) {
      setShowProfile(false);
      setShowLatestNews(false);
    } else {
      adjustDropdownPosition();
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('https://tionet.in/node/api/auth/logout', {}, {
        withCredentials: true, // Include cookies for session management
      });
      setUser({ name: 'Guest', photo: PPImage }); // Reset to guest user
      setIsOpen(false);
      setShowProfile(false);
      setShowLatestNews(false);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleProfile = (e) => {
    e.stopPropagation();
    setShowProfile((prev) => !prev);
    // If the profile is open, close other sections
    if (!showProfile) {
      setShowLatestNews(false);
    }
  };

  const toggleLatestNews = (e) => {
    e.stopPropagation();
    setShowLatestNews((prev) => !prev);
    // If the latest news is open, close the profile section
    if (!showLatestNews) {
      setShowProfile(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setShowProfile(false);
        setShowLatestNews(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Draggable functionality
  const handleMouseDown = (e) => {
    setIsDragging(true);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const newX = e.clientX - dropdownRect.width / 2;
      const newY = e.clientY - dropdownRect.height / 2;

      // Adjust positions for all components (dropdown, profile, latest news)
      let clampedX = Math.max(0, Math.min(newX, window.innerWidth - dropdownRect.width));
      let clampedY = Math.max(0, Math.min(newY, window.innerHeight - dropdownRect.height));

      setPosition({ x: clampedX, y: clampedY });

      // Handle flipping the components if they reach the edges
      if (clampedX <= 0 || clampedX + dropdownRect.width >= window.innerWidth) {
        // Flip position for Profile and LatestNews components
        if (showProfile) setShowProfile(false); // Hide profile if it's visible
        if (showLatestNews) setShowLatestNews(false); // Hide latest news if it's visible
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const getDropdownPosition = () => {
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const spaceAbove = dropdownRect.top;
    const spaceBelow = window.innerHeight - dropdownRect.bottom;

    return spaceAbove > spaceBelow ? 'up' : 'down';
  };

  useEffect(() => {
    if (dropdownContentRef.current) {
      const dropdownPosition = getDropdownPosition();
      if (dropdownPosition === 'up') {
        dropdownContentRef.current.style.bottom = '100%';
        dropdownContentRef.current.style.top = 'auto';
      } else {
        dropdownContentRef.current.style.top = '100%';
        dropdownContentRef.current.style.bottom = 'auto';
      }
    }
  }, [isOpen]);

  const handleItemClick = (e) => {
    e.stopPropagation();
  };

  const adjustDropdownPosition = () => {
    if (dropdownContentRef.current && dropdownButtonRef.current) {
      const rect = dropdownButtonRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownContentRef.current.offsetHeight;
      const dropdownWidth = dropdownContentRef.current.offsetWidth;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Calculate space above and below the dropdown button
      const spaceAbove = rect.top;
      const spaceBelow = windowHeight - rect.bottom;

      // Set dropdown to open in the available direction (up or down)
      if (spaceBelow > spaceAbove) {
        dropdownContentRef.current.style.top = '100%';
        dropdownContentRef.current.style.bottom = 'auto';
        dropdownContentRef.current.style.marginTop = '10px'; // Adjust gap
      } else {
        dropdownContentRef.current.style.top = 'auto';
        dropdownContentRef.current.style.bottom = '100%';
        dropdownContentRef.current.style.marginBottom = '10px'; // Adjust gap
      }

      // Ensure dropdown content doesn't go off-screen horizontally
      if (rect.left + dropdownWidth > windowWidth) {
        dropdownContentRef.current.style.left = 'auto';
        dropdownContentRef.current.style.right = '0';
      } else {
        dropdownContentRef.current.style.left = '0';
        dropdownContentRef.current.style.right = 'auto';
      }
    }
  };

  

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUser((prevUser) => ({
          ...prevUser,
          photo: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', adjustDropdownPosition);
    return () => {
      window.removeEventListener('resize', adjustDropdownPosition);
    };
  }, []);


  return (
    <div
      className="dropdown"
      ref={dropdownRef}
      style={{
        position: 'fixed',
        right: '20px', // Adjust the right distance
        top: '20px', // Adjust the top distance
        zIndex: 1000,
        maxWidth: '100%', // Prevent container overflow
    }}
      onMouseDown={handleMouseDown}
    >
      <button
        className="dropbtn"
        ref={dropdownButtonRef}
        onClick={toggleDropdown}
      >
        <img src={user.photo} alt="User" className="user-photo" />
        <span className="user-name">{user.name}</span>
        <i className={`menu-icon fa fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
      </button>
      <div
        className={`dropdown-content ${isOpen ? 'show' : ''}`}
        ref={dropdownContentRef}
        style={{
          position: 'absolute',
          // width: '220px',
        }}
        onClick={handleItemClick}
      >
        <a href="#" onClick={toggleProfile}><i className="fa fa-user"></i> Profile</a>
        <a href="#" onClick={toggleLatestNews}><i className="fa fa-newspaper"></i> Latest News</a>
        <Link to="/e-books"><i className="fa fa-book"></i> E-Books</Link>
        <Link to="/save-articles"><i className="fa fa-save"></i> Save Articles</Link>
        <Link to="/payments"><i className="fa fa-money-bill-alt"></i> Payments</Link>
        <Link to="/settings"><i className="fa fa-cog"></i> Settings</Link>
        <a href="#" onClick={handleLogout}><i className="fa fa-sign-out-alt"></i> Logout</a>
      </div>
      <input
        type="file"
        ref={imageUploadRef}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />

      {showProfile && <Profile user={user} />}{/* Conditionally show Profile */}
      {showLatestNews && <LatestNews />}
    </div>
  );
};

export default Dropdown;
