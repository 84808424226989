import React from 'react';
import { createRoot } from 'react-dom/client'; // React 18's createRoot
import App from './App';

// Get the root element
const rootElement = document.getElementById('root'); // Matches WordPress ID
if (rootElement) {
    const root = createRoot(rootElement); // Create a root
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
} else {
    console.error("Navbar root element not found. Make sure 'navbar-root' div is present in your WordPress HTML.");
}
