import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css"; // Import core styles
// import "swiper/css/pagination";
import "swiper/css/navigation";
import "./LatestNews.css"; // Make sure to import the CSS file

const LatestNews = () => {
  const smallContainerData = Array.from({ length: 9 }).map((_, index) => ({
    id: index,
    text: `Lorem ipsum dolor sit amet, container ${index + 1}.`,
  }));
  return (
    <div className="latestnews-container">
      <div className="latestnews-top">
        <h2>Latest News</h2>
      </div>
      
      <div className="sub-container">
        <div className="news-content-container">
          <ul className="cards">
            
            <li>
              <a href="#" className="card">
                <img
                  src="https://i.imgur.com/oYiTqum.jpg"
                  className="card__image"
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img
                      className="card__thumb"
                      src="https://i.imgur.com/7D7I6dI.png"
                      alt=""
                    />
                    <div className="card__header-text">
                      <h3 className="card__title">Jessica Parker</h3>
                      <span className="card__status">1 hour ago</span>
                    </div>
                  </div>
                  <p className="card__description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Asperiores, blanditiis?
                  </p>
                </div>
              </a>
            </li>


            <li>
              <a href="#" className="card">
                <img
                  src="https://i.imgur.com/2DhmtJ4.jpg"
                  className="card__image"
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img
                      className="card__thumb"
                      src="https://i.imgur.com/sjLMNDM.png"
                      alt=""
                    />
                    <div className="card__header-text">
                      <h3 className="card__title">Kim Cattrall</h3>
                      <span className="card__status">3 hours ago</span>
                    </div>
                  </div>
                  <p className="card__description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Asperiores, blanditiis?
                  </p>
                </div>
              </a>
            </li>


            <li>
              <a href="#" className="card">
                <img
                  src="https://i.imgur.com/oYiTqum.jpg"
                  className="card__image"
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img
                      className="card__thumb"
                      src="https://i.imgur.com/7D7I6dI.png"
                      alt=""
                    />
                    <div className="card__header-text">
                      <h3 className="card__title">Jessica Parker</h3>
                      <span className="card__status">1 hour ago</span>
                    </div>
                  </div>
                  <p className="card__description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Asperiores, blanditiis?
                  </p>
                </div>
              </a>
            </li>
          </ul>
      
      {/* Small Containers Section with Slider */}
      <div className="small-containers">
            <Swiper
              spaceBetween={16}
              slidesPerView={3}
              loop={true}
              navigation={true} // Enable navigation buttons
              modules={[Navigation]} // Use the Navigation module
              autoplay={{
                delay: 3000,
              }}
            >
              {smallContainerData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="small-container">
                    <p className="small-container-text">{item.text}</p>
                    <button className="read-more-button">Read More</button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
      </div>
    </div>
    </div>
  );
};

export default LatestNews;
