import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dropdown from './components/Dropdown';
import Profile from './components/Profile';
import Settings from './components/Settings';

const App = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  // Handle logout functionality
  const handleLogout = async () => {
    try {
      const response = await fetch('https://tionet.in/node/api/auth/logout', {
        method: 'POST',
        credentials: 'include', // Include credentials (cookies)
      });

      if (!response.ok) {
        throw new Error('Logout failed');
      }

      // Clear user data after logout
      setUserData(null);
      alert('Logged out successfully!');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Router>
      <div>
        {/* Pass down the userData and handleLogout to Dropdown component */}
        <Dropdown userData={userData} onLogout={handleLogout} setUserData={setUserData} />
        
        {/* Routes for profile and settings */}
        <Routes>
          <Route 
            path="/profile" 
            element={userData ? <Profile userData={userData} /> : <Navigate to="/" />} 
          />
          <Route 
            path="/settings" 
            element={userData ? <Settings userData={userData} /> : <Navigate to="/" />} 
          />
        </Routes>
      </div>

      {/* Display error message if user data couldn't be loaded */}
      {error && <div className="error">{error}</div>}
    </Router>
  );
};

export default App;
